<template>
  <MyLink v-bind="linkData" />
</template>

<script setup>
const props = defineProps({
  button: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: null,
  },
  noIcon: {
    type: Boolean,
    default: false,
  },
});
const { linkData } = await useSalesContactButton(props);
</script>
